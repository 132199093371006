import { toast } from "react-toastify";
import AlertPopup from "~/components/features/product/common/alert-popup";

let activeToastId = null;
export const alertToaster = (
  message,
  type = "info",
  position = "bottom-center",
  autoClose = 3000
) => {
  if (activeToastId && toast.isActive(activeToastId)) {
    return; // Exit if a toast is currently active
  }

  activeToastId = toast(<AlertPopup message={message} status={type} />, {
    position: position || "bottom-center",
    autoClose: autoClose || 3000,
    onClose: () => {
      activeToastId = null; // Reset the active toast ID when it closes
    },
  });
  return;
};
