import { call, put, select, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";

import { manageShoppingCart } from "~/graphql/api";
import { STORE_ID } from "~/config";
import { actionTypes } from "~/store/cart";
import { actionTypes as userActionTypes } from "~/store/user";
import { actionTypes as systemActionTypes } from "~/store/system";
import { errorHandler } from "~/utils/errorHandler";

export function* cartSaga() {
  yield takeEvery(
    [
      actionTypes.ADD_TO_CART,
      actionTypes.REMOVE_FROM_CART,
      actionTypes.APPLY_COUPONS,
      actionTypes.REMOVE_COUPON,
      actionTypes.UPDATE_CART,
      actionTypes.EMPTY_CART,
      userActionTypes.SET_USER,
      actionTypes.VALIDATE_CART_ON_ERROR,
      actionTypes.UPDATE_COUPON,
    ],
    function* saga(action) {
      try {
        if (action.type === "EMPTY_CART") {
          const { system } = yield select();
          yield put({
            type: systemActionTypes.SET_META,
            payload: { ...system?.meta, fbclid: "" },
          });

          yield put({ type: actionTypes.UPDATE_CART_ID, payload: null });
          return;
        }

        const { user, cart } = yield select();
        let { coupon, data: products } = cart;
        const { data: userData } = user;

        if (userData) {
          const { id: couponCode } = coupon || {};

          const data = products.map(
            ({ id, variantId, qty, cartItemSource }) => ({
              productId: id,
              variantId,
              quantity: qty,
              source: cartItemSource || null,
            })
          );

          const response = yield call([API, API.graphql], {
            query: manageShoppingCart,
            variables: {
              input: {
                couponCodeId: couponCode || null,
                storeId: STORE_ID,
                data,
              },
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          const cartId = response?.data?.manageShoppingCart?.shoppingCartId;

          yield put({ type: actionTypes.UPDATE_CART_ID, payload: cartId });
        }
      } catch (e) {
        errorHandler(e);
      }
    }
  );
}
